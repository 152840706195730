<template>
  <div>
    <!--************************** Active Slot Table **************************-->
    <div id="active-list">
      <b-card v-show="showFiltersActive" no-body>
        <b-card-header class="pb-50">
          <h5>
            {{ $t('common.filters') }}
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="12" md="3">
              <!-- startAt -->
              <b-form-group :label="$t('common.start_at')" label-for="state-filter">
                <b-overlay>
                  <b-form-datepicker
                    id="start-at"
                    v-model="paramsActiveSlot.startAt"
                    :locale="$i18n.locale"
                    :max="paramsActiveSlot.endAt"
                    :placeholder="$t('common.start_at')"
                    size="sm"
                  />
                </b-overlay>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
              <!-- endAt -->
              <b-form-group :label="$t('common.end_at')" label-for="state-filter">
                <b-overlay>
                  <b-form-datepicker
                    id="end-at"
                    v-model="paramsActiveSlot.endAt"
                    :locale="$i18n.locale"
                    :min="paramsActiveSlot.startAt"
                    :placeholder="$t('common.end_at')"
                    size="sm"
                  />
                </b-overlay>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="3">
              <!-- airports -->
              <b-form-group :label="$t('common.airport')" label-for="airport-filter">
                <b-overlay :show="filtersActiveSlotAirportsLoading">
                  <v-select
                    id="airport-filter"
                    v-model="paramsActiveSlot.airportId"
                    :options="activeSlotAirports"
                    :placeholder="$t('common.airport')"
                    :append-to-body="true"
                    :clearable="false"
                    :reduce="val => val.id"
                    :get-option-label="airport => airport.icaoCode ? airport.name + ' - ' + airport.icaoCode : airport.name"
                    class="select-size-sm"
                  />
                </b-overlay>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
              <!-- slot status -->
              <enum-select
                id="status-filter"
                v-model="paramsActiveSlot.slotStatus"
                :label="$t('slot_management.slot_status')"
                class="select-size-sm"
                enum-class="slotStatus"
              />
            </b-col>
            <b-col cols="12" md="3">
              <!-- movement types -->
              <enum-select
                id="movement-type-filter"
                v-model="paramsActiveSlot.movementType"
                :label="$t('slot_management.movement_type')"
                class="select-size-sm"
                enum-class="movementType"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="d-flex align-items-center justify-content-end">
              <b-button
                id="action-reset-filter"
                :disabled="!paramsActiveSlot.startAt && !paramsActiveSlot.endAt && !paramsActiveSlot.slotStatus && !paramsActiveSlot.movementType && !paramsActiveSlot.airportId"
                class="mr-1"
                size="sm"
                variant="outline-secondary"
                @click="clearFilters('active')"
              >
                {{ $t('action.clear') }}
              </b-button>
              <b-button id="action-filter" v-ripple.400 size="sm" @click="filtersSearch('active')">
                {{ $t('action.filter') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <!-- Table Container Card -->
      <b-card no-body class="mb-0 mt-1">
        <b-row class="m-2 d-flex align-items-center">
          <b-col cols="12" md="3">
            <h2> {{ $t('slot_management.active_slots') }}</h2>
          </b-col>
          <!-- TABLE TOP -->
          <b-col cols="12" md="9">
            <app-data-table-top
              :per-page="paramsActiveSlot.numberOfItemsPerPage"
              :per-page-options="dataTable.parPageOption"
              :search-placeholder="$t('slot_management.search_slot_number')"
              :show-filter="$can('TRIP_SLOT_MANAGEMENT_VIEW')"
              :show-per-page="$can('TRIP_SLOT_MANAGEMENT_VIEW')"
              :show-search="$can('TRIP_SLOT_MANAGEMENT_VIEW')"
              condensed="lg"
              size="md"
              table-name="active-slot-table"
              @table-select-per-page=";(paramsActiveSlot.numberOfItemsPerPage = $event), filtersSearch('active')"
              @table-search-text=";(paramsActiveSlot.slotNumber = $event), filtersSearch('active')"
              @table-filter="showFiltersActive = $event"
            />
          </b-col>
        </b-row>

        <app-data-table
          :busy="activeSlotLoading"
          :actions-table="{
            showShow: $can('TRIP_SLOT_MANAGEMENT_VIEW')
          }"
          :bottom-table="{ totalCount: paginationActiveSlot.totalItems }"
          :fields="activeSlotsManagementFields"
          :items="activeSlots"
          :page="paramsActiveSlot.page"
          :per-page="paramsActiveSlot.numberOfItemsPerPage"
          :small="$store.getters['app/lgAndDown']"
          manual-pagination
          table-name="active-slot-table"
          @show="
            $router.push({
              name: 'trip-view',
              params: { trip_id: $event.item.tripId },
            })
          "
          @pagination="paramsActiveSlot.page = $event"
        >
          <!-- LOADING -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>

          <!-- CELL -->
          <!-- slot number -->
          <template #cell(slotNumber)="data">
            <span> {{ data.item.slotNumber }} </span>
          </template>
          <!-- movement -->
          <template #cell(movementType)="data">
            {{ data.item.movementType }}
          </template>
          <!-- aircraft -->
          <template #cell(aircraftType)="data">
            {{ data.item.organizationAircraftModel }}
          </template>
          <!-- call sign / registration -->
          <template #cell(CallSignRegistration)="data">
            {{ data.item.organizationAircraftCallSign }} / {{ data.item.organizationAircraftRegistration }}
          </template>
          <!-- flight date -->
          <template #cell(flightDate)="data">
            <b-link>
              {{ $moment(data.item.eventDateTime).utc().format('ddd, MMM Do YYYY') }}
            </b-link>
          </template>
          <!-- slot time -->
          <template #cell(slotTimeUTC)="data">
            <span v-if="data.item.slotStartDate && data.item.slotEndDate" class="text-nowrap">
              {{ $moment(data.item.slotStartDate).utc().format('HH:mm') }} - {{
                $moment(data.item.slotEndDate).utc().format('HH:mm')
              }}
            </span>
            <span v-else class="text-nowrap">
              {{ $moment(data.item.eventDateTime).utc().format('HH:mm') }} - {{
                $moment(data.item.endsAt).utc().format('HH:mm')
              }}
            </span>
          </template>
          <!-- airport -->
          <template #cell(airport)="data">
            {{ data.item.airportName }} - {{ data.item.airportIcaoCode }}
          </template>
          <!-- origin / dest -->
          <template #cell(originDest)="data">
            {{ data.item.otherEventAirportName }} - {{ data.item.otherEventAirportIcaoCode }}
          </template>
          <!-- status -->
          <template #cell(status)="data">
            <b-badge
              :variant="`${resolveSlotStatusVariant(data.item.slotStatus)}`"
              class="font-small-3 mr-50"
            >
              {{ data.item.slotStatus|enumTranslate('slot_status') }}
            </b-badge>
          </template>

        </app-data-table>
        <!--TABLE BOTTOM-->

      </b-card>

    </div>

    <!--************************** Cancelled Slot Table **************************-->
    <b-card v-show="showFiltersCancelled" no-body class="mt-2">
      <b-card-header class="pb-50">
        <h5>
          {{ $t('common.filters') }}
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3">
            <!-- startAt -->
            <b-form-group :label="$t('common.start_at')" label-for="state-filter">
              <b-overlay>
                <b-form-datepicker
                  id="start-at-cancelled"
                  v-model="paramsCancelledSlot.startAt"
                  :locale="$i18n.locale"
                  :max="paramsCancelledSlot.endAt"
                  :placeholder="$t('common.start_at')"
                  size="sm"
                />
              </b-overlay>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <!-- endAt -->
            <b-form-group :label="$t('common.end_at')" label-for="state-filter">
              <b-overlay>
                <b-form-datepicker
                  id="end-at-cancelled"
                  v-model="paramsCancelledSlot.endAt"
                  :locale="$i18n.locale"
                  :min="paramsCancelledSlot.startAt"
                  :placeholder="$t('common.end_at')"
                  size="sm"
                />
              </b-overlay>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <!-- airports -->
            <b-form-group :label="$t('common.airport')" label-for="airport-filter">
              <b-overlay :show="filtersCancelledSlotAirportsLoading">
                <v-select
                  id="airport-filter-cancelled"
                  v-model="paramsCancelledSlot.airportId"
                  :options="cancelledSlotAirports"
                  :placeholder="$t('common.airport')"
                  :append-to-body="true"
                  :clearable="false"
                  :reduce="val => val.id"
                  :get-option-label="airport => airport.icaoCode ? airport.name + ' - ' + airport.icaoCode : airport.name"
                  class="select-size-sm"
                />
              </b-overlay>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <!-- movement types -->
            <enum-select
              id="movement-type-filter-cancelled"
              v-model="paramsCancelledSlot.movementType"
              :label="$t('slot_management.movement_type')"
              class="select-size-sm"
              enum-class="movementType"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="d-flex align-items-center justify-content-end">
            <b-button
              id="action-reset-filter-cancelled"
              :disabled="!paramsCancelledSlot.startAt && !paramsCancelledSlot.endAt && !paramsCancelledSlot.slotStatus && !paramsCancelledSlot.movementType && !paramsCancelledSlot.airportId"
              class="mr-1"
              size="sm"
              variant="outline-secondary"
              @click="clearFilters('cancelled')"
            >
              {{ $t('action.clear') }}
            </b-button>
            <b-button id="action-filter-cancelled" v-ripple.400 size="sm" @click="filtersSearch('cancelled')">
              {{ $t('action.filter') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <div id="canceled-list" class="mb-0 mt-2">

      <!-- Filters -->

      <!-- Table Container Card -->
      <b-card no-body class="mb-0 mt-1">
        <b-row class="m-2 d-flex align-items-center">
          <b-col cols="12" md="3">
            <h2> {{ $t('slot_management.cancelled_slots') }}</h2>
          </b-col>
          <!-- TABLE TOP -->
          <b-col cols="12" md="9">
            <app-data-table-top
              :per-page="paramsCancelledSlot.numberOfItemsPerPage"
              :per-page-options="dataTable.parPageOption"
              :search-placeholder="$t('slot_management.search_slot_number')"
              :show-filter="$can('TRIP_SLOT_MANAGEMENT_VIEW')"
              :show-per-page="$can('TRIP_SLOT_MANAGEMENT_VIEW')"
              :show-search="$can('TRIP_SLOT_MANAGEMENT_VIEW')"
              condensed="lg"
              size="md"
              table-name="canceled-slot-table"
              @table-select-per-page=";(paramsCancelledSlot.numberOfItemsPerPage = $event), filtersSearch('cancelled')"
              @table-search-text=";(search = $event), filtersSearch('cancelled')"
              @table-search-filter=";(searchField = $event), filtersSearch('cancelled')"
              @table-filter="showFiltersCancelled = $event"
            />
          </b-col>
        </b-row>

        <app-data-table
          :busy="cancelledSlotLoading"
          :bottom-table="{ totalCount: paginationCancelledSlot.totalItems }"
          :fields="cancelledSlotManagementFields"
          :items="cancelledSlots"
          :page="paramsCancelledSlot.page"
          :per-page="paramsCancelledSlot.numberOfItemsPerPage"
          :small="$store.getters['app/lgAndDown']"
          :sort-by.sync="sort.sortBy"
          :sort-desc.sync="sort.sortDesc"
          manual-pagination
          table-name="canceled-slot-table"
          @pagination="paramsCancelledSlot.page = $event"
        >
          <!-- LOADING -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>

          <!-- CELL -->
          <!-- slot number -->
          <template #cell(slotNumberCancelled)="data">
            <span> {{ data.item.slotNumber }} </span>
          </template>
          <!-- movement -->
          <template #cell(movementTypeCancelled)="data">
            {{ data.item.movementType }}
          </template>
          <!-- aircraft -->
          <template #cell(aircraftTypeCancelled)="data">
            {{ data.item.organizationAircraftModel }}
          </template>
          <!-- call sign / registration -->
          <template #cell(CallSignRegistrationCancelled)="data">
            {{ data.item.organizationAircraftCallSign }} / {{ data.item.organizationAircraftRegistration }}
          </template>
          <!-- flight date -->
          <template #cell(flightDateCancelled)="data">
            <b-link>
              {{ $moment(data.item.eventDateTime).utc().format('ddd, MMM Do YYYY') }}
            </b-link>
          </template>
          <!-- slot time -->
          <template #cell(slotTimeUTCCancelled)="data">
            <span v-if="data.item.slotStartDate && data.item.slotEndDate" class="text-nowrap">
              {{ $moment(data.item.slotStartDate).utc().format('HH:mm') }} - {{
                $moment(data.item.slotEndDate).utc().format('HH:mm')
              }}
            </span>
          </template>
          <!-- airport -->
          <template #cell(airportCancelled)="data">
            {{ data.item.airportName }} - {{ data.item.airportIcaoCode }}
          </template>
          <!-- origin/dest -->
          <template #cell(originDestCancelled)="data">
            {{ data.item.otherEventAirportName }} - {{ data.item.otherEventAirportIcaoCode }}
          </template>

        </app-data-table>
        <!--TABLE BOTTOM-->

      </b-card>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UIHelper from '@/helpers/ui'
import AppDataTable from '@/components/AppDataTable.vue'
import AppDataTableTop from '@/components/AppDataTableTop.vue'

import {
  fetchActiveSlotAirports,
  fetchActiveSlotManagementRequest,
  fetchCancelledSlotAirports,
  fetchCancelledSlotManagementRequest,
} from '@/request/globalApi/requests/slotManagementRequests'

export default {
  name: 'SlotManagement',

  components: {
    AppDataTable,
    AppDataTableTop,
  },

  mixins: [UIHelper],
  data() {
    return {
      activeSlots: [],
      activeSlotLoading: true,
      cancelledSlots: [],
      cancelledSlotLoading: true,
      showFiltersActive: false,
      showFiltersCancelled: false,
      quotesFieldsLoading: true,
      quotesFields: [],
      paginationActiveSlot: { totalItems: 0 },
      paginationCancelledSlot: { totalItems: 0 },
      filtersActiveSlotAirportsLoading: true,
      activeSlotAirports: [],
      filtersCancelledSlotAirportsLoading: true,
      cancelledSlotAirports: [],
      activeSlotsManagementFields: [
        { key: 'slotNumber', label: this.$t('slot_management.slot_number'), sortable: false },
        { key: 'movementType', label: this.$t('slot_management.movement_type'), sortable: false },
        { key: 'aircraftType', label: this.$t('slot_management.aircraft_type'), sortable: false },
        { key: 'CallSignRegistration', label: this.$t('slot_management.call_sign_registration'), sortable: false },
        { key: 'flightDate', label: this.$t('slot_management.flight_date'), sortable: false },
        { key: 'slotTimeUTC', label: this.$t('slot_management.slot_time_UTC'), sortable: false },
        { key: 'airport', label: this.$t('slot_management.airport'), sortable: false },
        { key: 'originDest', label: this.$t('slot_management.origin_dest'), sortable: false },
        { key: 'status', label: this.$t('slot_management.status'), sortable: false },
      ],
      cancelledSlotManagementFields: [
        { key: 'slotNumberCancelled', label: this.$t('slot_management.slot_number'), sortable: false },
        { key: 'movementTypeCancelled', label: this.$t('slot_management.movement_type'), sortable: false },
        { key: 'aircraftTypeCancelled', label: this.$t('slot_management.aircraft_type'), sortable: false },
        {
          key: 'CallSignRegistrationCancelled',
          label: this.$t('slot_management.call_sign_registration'),
          sortable: false,
        },
        { key: 'flightDateCancelled', label: this.$t('slot_management.flight_date'), sortable: false },
        { key: 'slotTimeUTCCancelled', label: this.$t('slot_management.slot_time_UTC'), sortable: false },
        { key: 'airportCancelled', label: this.$t('slot_management.airport'), sortable: false },
        { key: 'originDestCancelled', label: this.$t('slot_management.origin_dest'), sortable: false },
      ],
      sort: {
        sortBy: null,
        sortDesc: null,
      },
      paramsActiveSlot: {
        page: 1,
        numberOfItemsPerPage: 5,
        startAt: null,
        endAt: null,
        airportId: null,
        organizationAircraftType: null,
        slotStatus: null,
        movementType: null,
        slotNumber: null,
      },
      paramsCancelledSlot: {
        page: 1,
        numberOfItemsPerPage: 5,
        startAt: null,
        endAt: null,
        airportId: null,
        organizationAircraftType: null,
        slotStatus: null,
        movementType: null,
        slotNumber: null,
      },
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
    ...mapState('appConfig', ['lang', 'dataTable']),

    allFilters() {
      return {
        numberOfItemsPerPage: this.paramsActiveSlot.numberOfItemsPerPage,
        page: this.paramsActiveSlot.page,
        // SEARCH
        startAt: this.paramsActiveSlot.startAt,
        endAt: this.paramsActiveSlot.endAt,
        airportId: this.paramsActiveSlot.airportId,
        organizationAircraftType: this.paramsActiveSlot.organizationAircraftType,
        slotStatus: this.paramsActiveSlot.slotStatus,
        movementType: this.paramsActiveSlot.movementType,
        slotNumber: this.paramsActiveSlot.slotNumber,
      }
    },
    allFiltersCancelled() {
      return {
        numberOfItemsPerPage: this.paramsCancelledSlot.numberOfItemsPerPage,
        page: this.paramsCancelledSlot.page,
        // SEARCH
        startAt: this.paramsCancelledSlot.startAt,
        endAt: this.paramsCancelledSlot.endAt,
        airportId: this.paramsCancelledSlot.airportId,
        organizationAircraftType: this.paramsCancelledSlot.organizationAircraftType,
        slotStatus: this.paramsCancelledSlot.slotStatus,
        movementType: this.paramsCancelledSlot.movementType,
        slotNumber: this.paramsCancelledSlot.slotNumber,
      }
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.APIfetchActiveSlotManagement()
      },
      deep: true,
    },
    allFiltersCancelled: {
      handler() {
        this.APIfetchCancelledSlotManagement()
      },
      deep: true,
    },
  },
  mounted() {
    this.APIfetchActiveSlotAirports()
    this.APIfetchCancelledSlotAirports()
    this.APIfetchActiveSlotManagement()
    this.APIfetchCancelledSlotManagement()
  },
  methods: {
    APIfetchActiveSlotManagement() {
      this.activeSlotLoading = true
      fetchActiveSlotManagementRequest(this.paramsActiveSlot).then(response => {
        const { slots } = response.data
        this.activeSlots = slots
        this.paginationActiveSlot.totalItems = response.data.totalItems
      }).finally(() => {
        this.activeSlotLoading = false
      })
    },
    APIfetchCancelledSlotManagement() {
      this.cancelledSlotLoading = true
      fetchCancelledSlotManagementRequest(this.paramsCancelledSlot).then(response => {
        const { slots } = response.data
        this.cancelledSlots = slots
        this.paginationCancelledSlot.totalItems = response.data.totalItems
      }).finally(() => {
        this.cancelledSlotLoading = false
      })
    },
    APIfetchActiveSlotAirports() {
      this.filtersActiveSlotAirportsLoading = true
      fetchActiveSlotAirports(this.selectedOrganization.id).then(response => {
        this.activeSlotAirports = response.data
      }).finally(() => {
        this.filtersActiveSlotAirportsLoading = false
      })
    },
    APIfetchCancelledSlotAirports() {
      this.filtersCancelledSlotAirportsLoading = true
      fetchCancelledSlotAirports(this.selectedOrganization.id).then(response => {
        this.cancelledSlotAirports = response.data
      }).finally(() => {
        this.filtersCancelledSlotAirportsLoading = false
      })
    },
    clearFilters(slotType) {
      if (slotType === 'active') {
        this.paramsActiveSlot.startAt = null
        this.paramsActiveSlot.endAt = null
        this.paramsActiveSlot.airportId = null
        this.paramsActiveSlot.organizationAircraftType = null
        this.paramsActiveSlot.slotStatus = null
        this.paramsActiveSlot.movementType = null
        this.APIfetchActiveSlotManagement()
      } else {
        this.paramsCancelledSlot.startAt = null
        this.paramsCancelledSlot.endAt = null
        this.paramsCancelledSlot.airportId = null
        this.paramsCancelledSlot.organizationAircraftType = null
        this.paramsCancelledSlot.slotStatus = null
        this.paramsCancelledSlot.movementType = null
        this.APIfetchCancelledSlotManagement()
      }
    },
    filtersSearch(slotType) {
      if (slotType === 'active') {
        this.APIfetchActiveSlotManagement()
      } else {
        this.APIfetchCancelledSlotManagement()
      }
    },

  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

@media (min-width: 992px) {
  .input-group-prepend .select-search {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media (max-width: 992px) {
  .organizationAircraftAdd,
  .input-group-prepend,
  .per-page-selector {
    width: 100%;
  }

  .input-group-prepend {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group-prepend input {
    border-top-left-radius: 0;
  }

  .input-group-append button {
    border-top-right-radius: 0;
  }
}
</style>
